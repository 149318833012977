import type {SagaIterator, Task} from "redux-saga";
import type {CallEffect, PutEffect} from "redux-saga/effects";
import root from "window-or-global";
import {
    call,
    put,
    select,
    race,
    takeLatest,
    takeLeading,
    take,
    delay,
    cancel,
    spawn,
} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import * as LoginTypes from "@atg-login-shared/types";
import * as device from "@atg/utils/device";
import * as AccessTokenActions from "@atg-shared/auth/domain/accessTokenActions";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {UserGamblingSummaryActions} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";
import {LOGIN_FINISHED, LOGOUT_USER} from "@atg-global-shared/user/userActionTypes";
import * as UserActions from "@atg-global-shared/user/userActions";
import {isSafariOniOS} from "@atg-login/utils/src/devices";
import {MemberActions, MemberSelectors} from "@atg-global-shared/member-data-access";
import {LoginErrorMessages, LoginDevices, Analytics} from "@atg-login/utils";
import {LoginSelectors} from "@atg-login-shared/data-access";
import {t} from "@lingui/macro";
import * as ModalActions from "atg-modals/modalActions";
import {AUTH_SERVER_RGS_NEXT_POSSIBLE_LOGIN} from "atg-iframe/components/CurityIFrameConstants";
import type * as ContactInfoActions from "./contactInfoActions";
import * as LoginActions from "./loginActions";
import * as EIDActions from "./eidActions";
import LoginApi, {logout as loginApiLogout} from "./loginApi";

/**
 * adds a "LoginSaga:" prefix to the log message, includes the error (serialized), and adds a custom saga and context metadata. Helpful for Splunk searches.
 * @param message The message to log (e.g. "Authorization Error")
 * @param error Error object to log, will be serialized
 */
export const logLoginSagaError = (message: string, error: unknown) => {
    log.error(message, {
        error: serializeError(error),
        saga: "loginSaga",
        context: "CustomerExperience",
    });
};

let loginApi: LoginApi;

export function* resetStates(): SagaIterator {
    yield put(LoginActions.stopTokenMonitor());
    yield put(LoginActions.resetCurityState());
}

/**
 * This saga will run when the step `"https://curity.se/problems/error-authorization-response"` is returned.
 * It logs the error and set the error state in the store.
 * If the error is `access_denied` it will show an error message to the user.
 * @param response
 */
export function* handleErrorAuthResponse(
    response: LoginTypes.ErrorAuthorizationResponse,
) {
    if (response.type === LoginTypes.StepType.ErrorAuthResponseStep) {
        const {error} = response;
        const errorDescription =
            response.error_description ?? "An unknown error occurred";

        // This will make sure the "private relay" error message only reaches iOS safari users
        const friendlyErrorMessage = isSafariOniOS()
            ? LoginErrorMessages.getFriendlyErrorMessage(errorDescription)
            : LoginErrorMessages.getFriendlyErrorMessage("An unknown error occurred");

        yield call(logLoginSagaError, "Authorization Error", response);

        Analytics.trackLoginEvent({
            event: "authErrorResponse",
            error,
            errorDescription,
        });

        if (error === "access_denied") {
            yield put(
                LoginActions.setCurityError({
                    messages: [
                        {
                            text: friendlyErrorMessage,
                            classList: ["error"],
                        },
                    ],
                }),
            );
        }
    }
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.AUTHORIZATION_ERROR));
}

/**
 * Structures the error message and setting it on the curity error property in the store.
 * Also responsible for setting the generic error ui and cancels any other sagas.
 * This can be used when you want to remove any other modals and display a generic error message.
 * @param error Will probably be a javascript error or a Curity API error.
 */
export function* handleUnexpectedError(error: unknown) {
    yield call(handleError, error);
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.UNEXPECTED_ERROR));
    yield cancel();
}

/**
 * Will set the error on the store and can be used to present it in the flow where the error happend
 */
export function* handleError(error: unknown) {
    const message = LoginErrorMessages.handleErrorMessage(error);
    yield put(
        LoginActions.setCurityError({
            messages: [{text: message, classList: ["error"]}],
        }),
    );
}

/**
 * Present an error showing a message in case the LOGIN_ERROR action is
 * dispatched after completing the curity flow.
 *
 * e.g: In case it failing during member/api/v3/member call after curity flow.
 */

export function* handleErrorAfterCurityFlow(action: {[key: string]: any}): SagaIterator {
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.UNEXPECTED_ERROR));
    yield call(
        logLoginSagaError,
        "Login error after completed haapi flow",
        action?.payload?.error,
    );
}

/**
 * Initiate a curity flow and store the response in Redux Store.
 */
export function* curityInit() {
    try {
        yield put(LoginActions.startTokenMonitor());
        loginApi = new LoginApi();
        const response: LoginTypes.AuthenticationStep = yield call(loginApi.init);
        const model = response.actions[0].model as LoginTypes.SelectorTemplateModel;
        yield put(LoginActions.setAuthenticators(model.options));
        yield put(LoginActions.setCurityResponse(response));
    } catch (error: unknown) {
        yield call(logLoginSagaError, "Initiating curity saga", error);
        yield call(handleUnexpectedError, error);
    }
}

/**
 * Requests a new curity flow and displays the authenticators view.
 * Can be used when you know you want to go straight to
 * the authenticators (i.e. Footer back buttons)
 * without any other logic interfering
 */
export function* selectAuthMethod() {
    yield call(resetStates);
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING));
    yield call(curityInit);
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.AUTHENTICATOR_SELECTOR));
}

/**
 * Starts a completely new login flow.
 * It will also call saga to check if user has logged in with any authenticator before and use that if they have.
 */
export function* initSaga() {
    yield call(resetStates);
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING));
    yield call(curityInit);

    yield call(handleCheckForLastLoginMethod);
}

/**
 * Handle last login method
 * Will check if the user has authenticated themselves with any of the authenticators before and use that as the default login method if they have.
 * Users who are registering won't have any method saved and the view will default to the `authenticators-selector` view.
 */
export function* handleCheckForLastLoginMethod(): SagaIterator {
    const startView: LoginTypes.AuthMethod = yield select(
        LoginSelectors.selectStoredAuthMethod,
    );
    const isBankID = startView.method === LoginTypes.CurityState.BANKID;
    const isFrejaID = startView.method === LoginTypes.CurityState.FREJAEID;
    const isUsernamePassword = startView.method === LoginTypes.CurityState.ATG_CAPTCHA;

    yield put(LoginActions.setCurityState(startView.method));

    if (startView.method !== LoginTypes.CurityState.AUTHENTICATOR_SELECTOR) {
        yield put(LoginActions.setAuthContext(startView.context));
        const smallScreen = device.isiOS() || device.isAndroid();

        if (smallScreen && (isBankID || isFrejaID)) {
            yield put(LoginActions.setCurityState(LoginTypes.CurityState.AUTH_STARTER));
            return;
        }
        if (isBankID) {
            yield put(EIDActions.initBankIDQR());
        }
        if (isFrejaID) {
            yield put(EIDActions.initFrejaIDQR());
        }
        if (isUsernamePassword) {
            const authenticators = yield select(
                LoginSelectors.selectCurityAuthenticators,
            );
            const atgCaptchaModel = authenticators[1].model;
            yield call(handleCurityRequest, atgCaptchaModel);
        }
    }
}

/**
 * Handle curity request
 * Receive a template model and execute a request using CurityApi `_haapiFetch` a function
 * similar to fetch used to perform HAAPI requests and return the response.
 * @param {Object} model - `CurityTypes.FormTemplateModel`
 * @param {Object} data - an object that contains form values
 */
export function* handleCurityRequest(
    model: LoginTypes.FormTemplateModel,
    data?: Record<string, string | boolean | null>,
) {
    let {href} = model;
    const hasBody = model.method && ["POST", "PUT", "DELETE"].includes(model.method);
    const options: Record<string, unknown> = {
        method: model.method,
    };

    // Check if href is a relative path for atg-create-account/is-blocked
    if (
        href &&
        href.includes("atg-create-account/is-blocked") &&
        !href.startsWith("http") &&
        !href.startsWith("/")
    ) {
        // Temporary fix: Prepend the base authentication path to create the complete URI
        // This will only apply if the href doesn't already start with http:// or https:// or /
        href = `/authn/authenticate/_action/${href}`;
    }

    // Set headers if content-type is specified in the model
    if (model.type) {
        const headers = new Headers();
        headers.set("Content-Type", model.type);
        options.headers = headers;
    }

    // handle body for POST, PUT, DELETE requests
    if (hasBody) {
        let fields: Record<string, unknown> = {};
        if (model.fields) {
            fields = model.fields.reduce((acc, field) => {
                // make sure marketing consent checkbox isn't overwritten by the haapi default values
                if (field.value && field.name !== "newsletter-checkbox") {
                    acc[field.name] = field.value;
                    return acc;
                }

                if (data) {
                    acc[field.name] = data[field.name];
                    return acc;
                }

                return acc;
            }, {} as Record<string, string | boolean | null>);
        }

        delete fields.siteKey;

        const body = {
            ...fields,
            ...data,
        };
        options.body = body;
    }

    if (!href) return;

    const response: LoginTypes.CurityRootModel = yield call(
        loginApi.getHaapiFetchResponse,
        href,
        options,
    );
    yield call(handleResponseByStatusCode, response);
}

/**
 * Called at the end of the `handleCurityRequest`. The response will have a status code which should be used to decide
 * the direction of the flow, if errors should be set or not.
 * @param response The API response, including the status code set explicitly by us in `getHaapiFetchResponse`
 */
export function* handleResponseByStatusCode(
    response: LoginTypes.CurityRootModel,
): SagaIterator {
    const state: LoginTypes.CurityState = yield select(LoginSelectors.getCurityState);
    const isNotCaptcha = state !== LoginTypes.CurityState.ATG_CAPTCHA;

    if (
        response.metadata?.viewName ===
        "authentication-action/atg-create-account/is-blocked"
    ) {
        yield put(LoginActions.setCurityResponse(response));

        Analytics.trackLoginEvent({
            event: "authErrorResponse",
            error: "is-blocked",
            errorDescription: "User is blocked",
        });

        yield put(LoginActions.setCurityState(LoginTypes.CurityState.USER_BLOCKED));

        return; // Prevent further processing
    }

    // Only call the `setCurityResponseSuccess` action to reset any errors if
    // it is not `atg-captcha` state to keep the error even when the request is successful.
    if (response.status < 400) {
        yield put(LoginActions.setCurityResponse(response));
        if (
            isNotCaptcha ||
            (!isNotCaptcha &&
                response.metadata?.viewName === "protocol/simple-api/postback") // clear errors if we are in captcha and the post was successful
        ) {
            yield put(LoginActions.setCurityResponseSuccess());
        }
    } else if (response.status >= 500) {
        yield put(
            LoginActions.setCurityError({code: LoginTypes.CurityState.SERVER_ERROR}),
        );
    } else if (
        // to catch the case where the user has been inactive for a long time during login process and the dPop token has expired
        response.messages &&
        response.messages[0]?.text === "Begäran kunde inte utföras"
    ) {
        yield call(handleExpiredThumbprint);
    } else if (
        // ex: Will catch cases where backend responds with an access denied message by non matching ip address
        response.status === 400 &&
        response.type === LoginTypes.StepType.ErrorAuthResponseStep
    ) {
        yield put(LoginActions.setCurityResponse(response));
    } else {
        const lastCurityResponse = yield select(LoginSelectors.selectCurityResponse);

        // in case request failed with no status and is in polling step, we want
        // to log it and wait to the next polling to complete the flow
        if (
            !response.status &&
            lastCurityResponse?.type === LoginTypes.StepType.PollingStep
        ) {
            Analytics.trackLoginEvent({
                event: "errorMessage",
                errorMessage: "request failed during polling step",
                context: state,
                component: "handleResponseByStatusCode",
            });
            yield call(logLoginSagaError, "Request failed during polling step", response);
            return;
        }

        yield put(LoginActions.setCurityError(response as LoginTypes.CurityError));
    }
}

/**
 * Handle the expired thumbprint within a dPop JWT.
 * This is a special case where the user has started a login flow and then left the modal open for a long time.
 */
export function* handleExpiredThumbprint() {
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.EXPIRED_THUMBPRINT));
}

/**
 * Register User
 * Handle register user flow using payload to get the response
 * and call handleCurityFlow to finish the flow and show the welcome modal.
 * @param {Object} action - `ContactInfoActions.RegisterUserContactInfo`
 * @property {string} action.type - `curity/REGISTER_USER_CONTACT_INFO`
 * @property {Object} action.payload - `CurityTypes.RegisterUserInfo`
 */
export function* registerUser(
    action: ContactInfoActions.RegisterUserContactInfo,
): SagaIterator {
    const {payload} = action;
    const curityResponse = yield select(LoginSelectors.selectCurityResponse);

    yield call(handleCurityRequest, curityResponse.actions[0]?.model, payload);

    const error = yield select(LoginSelectors.selectError);

    if (error?.status >= 500) {
        yield put(
            LoginActions.setCurityError({
                messages: [
                    {
                        text: LoginErrorMessages.getServerErrorMessage(),
                        classList: ["error"],
                    },
                ],
            }),
        );
    }
    yield call(handleCurityFlow);
}

export function* setRegister() {
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.REGISTER));
}

/**
 * Store the method the user used to present as the option next time.
 * Saga that stores the current context (`atg-captch` | `bankid` | `freja-eid`) in local storage
 * Also call setShowBalance to be able to update the visibility of the balance.
 * Note: the priority of the hideBalance will always be what the user choose from curity login flow not from user store.
 *
 *  @param {string} method - `CurityTypes.CurityState`
 *  @param {string} context - `freja-eid | atg-captcha | bankid`
 *  @param {boolean} isSuccessfulLogin
 *
 */
export function* handleStoreAuthMethod(
    method: LoginTypes.CurityState,
    context: LoginTypes.AuthenticatorTypes | null,
    isSuccessfulLogin: boolean,
): SagaIterator {
    const hideBalance: boolean = yield select(LoginSelectors.getHideBalanceLoginFlow);
    yield put(
        LoginActions.setAuthMethod({method, context, isSuccessfulLogin, hideBalance}),
    );
    yield put(UserActions.setShowBalance(!hideBalance));
}

/**
 * Store the the current auth preferences in a temporary object while the user is
 * still authorizing to be able to persist it in localStorage after a successful login.
 * This temporary object is deleted on the when RESET_CURITY_STATE is dispatched
 *  @param {Object} action - `LoginActions.CurityInitUNPW | LoginActions.InitBankID | LoginActions.InitFrejaID`
 *  @param {string} authContext - `freja-eid | atg-captcha | bankid`
 */
export function* setTempAuthMethod(
    action: LoginActions.CurityLoginAction,
    authContext: LoginTypes.AuthenticatorTypes,
): SagaIterator {
    const preferredMethod: LoginTypes.CurityState = yield select(
        LoginSelectors.selectPreferredAuthMethod(action.type),
    );
    const hideBalance: boolean = yield select(LoginSelectors.getHideBalanceLoginFlow);

    const tempAuthMethod = {
        method: preferredMethod,
        context: authContext,
        isSuccessfulLogin: false,
        hideBalance,
    };

    yield put(LoginActions.setTempAuthMethod(tempAuthMethod));
}
/**
 * Authorize Login
 * Receive the link item to authenticate the user, once it was success will
 * reset the states, set the state as pending to be able to show the loader indicator
 * before show the welcome modal or contact information modal.
 * @param {Object} linkItem - Contains the href needed to get the tokens `CurityTypes.RedirectionFormModel`
 */
export function* authorizeLogin(linkItem: LoginTypes.LinkItem): SagaIterator {
    const url = linkItem.href;
    try {
        const verifyCodeResponse = yield call(loginApi.verifyCode, url);
        if (verifyCodeResponse) {
            const {access_token: accessToken, id_token: idToken} = verifyCodeResponse;
            yield put(
                AccessTokenActions.authenticationSuccess(
                    accessToken,
                    JSON.parse(idToken),
                ),
            );
            const action = yield take([LOGIN_FINISHED]);

            // consider a user authorized from here
            const isCaptchaRequired = localStorage.getItem("captchaOn");
            if (isCaptchaRequired) {
                localStorage.removeItem("captchaOn");
            }

            if (action?.type === LOGIN_FINISHED) {
                yield put(
                    UserGamblingSummaryActions.fetchLatestSuccessfulLoginTimeAction(),
                );
                yield put(UserGamblingSummaryActions.fetchGamblingResultAction());

                /**
                 * The id token contains info if the user is a returning user
                 * or a new user.
                 *  - All new user should see the welcome modal
                 *  - All returning users should see the ATG check modal even if the  skipped setting limits in the first step.
                 *  - Should trigger memberRegisterFinished action if the user is a new.
                 */
                const {memberFlow}: AccessTokenActions.IDToken = yield select(
                    AuthSelectors.getIDToken,
                );

                if (memberFlow === "NEW_MEMBER_FROM_LOGIN_FLOW") {
                    yield put(MemberActions.memberRegisterFinished());
                    yield put(ModalActions.showWelcomeNewUserModal());
                } else {
                    yield put(ModalActions.showUserGamblingSummaryModal());
                }

                const authMethod: LoginTypes.AuthMethod = yield select(
                    LoginSelectors.selectStoredAuthMethod,
                );
                yield put(UserActions.setShowBalance(!authMethod.hideBalance));

                const isRegister = yield select(MemberSelectors.isRegister);

                // in case the user is registering, we should cancel the flow and redirect back if is in the skapakonto page
                if (isRegister) {
                    yield put(MemberActions.memberRegisterCancel());
                }

                if (
                    root.location.pathname === "/loggain" ||
                    root.location.pathname === "/skapakonto"
                ) {
                    window.history.back();
                }
                // if the user has a stored login method, there will be no temp property and we can return early
                if (!authMethod.temp) {
                    yield call(resetStates);
                    yield put(MemberActions.finishMemberFlow());
                    return;
                }

                const {
                    temp: {method, context},
                } = authMethod;

                yield call(handleStoreAuthMethod, method, context, true);
                yield call(resetStates);
                yield put(MemberActions.finishMemberFlow());
            }
        }
    } catch (error: unknown) {
        const serializedError = serializeError(error);
        if (
            serializedError?.response?.data?.error === AUTH_SERVER_RGS_NEXT_POSSIBLE_LOGIN
        ) {
            yield put(MemberActions.cancelLoginFlow());
            yield put(
                UserActions.setNextPossibleLogin(
                    serializedError.response.data.nextPossibleLogin,
                ),
            );
            yield put(ModalActions.showRgsAtgCheckModal());
            return;
        }
        yield call(logLoginSagaError, "Error Authorizing login", error);
        yield call(handleUnexpectedError, error);
    }
}

export function* submitUsernameAndPassword(
    action: LoginActions.CuritySubmitUNPW,
): SagaIterator {
    try {
        yield put(LoginActions.setCurityError(null));
        yield put(LoginActions.loginCredentialsRequestStart());

        const curityResponse: LoginTypes.AuthenticationStep = yield select(
            LoginSelectors.selectCurityResponse,
        );

        yield call(
            handleCurityRequest,
            curityResponse.actions[0].model as LoginTypes.FormTemplateModel,
            action.payload,
        );

        const curityError: LoginTypes.CurityError = yield select(
            LoginSelectors.selectError,
        );

        // do a get request to see if the html-scim response has captcha information
        if (curityError?.status === 400) {
            const usernameAndPasswordAuthenticator = yield select(
                LoginSelectors.selectInitMethod(action.type),
            );
            yield call(handleCurityRequest, usernameAndPasswordAuthenticator.model);
        }

        yield call(handleSmsVerificationState);

        yield call(handleCurityFlow);
    } catch (error: unknown) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.UNEXPECTED_ERROR));
    } finally {
        yield put(LoginActions.loginCredentialsRequestFinish());
    }
}

export function* submitLegalID(action: LoginActions.CuritySubmitLegalID): SagaIterator {
    const curityResponse: LoginTypes.AuthenticationStep = yield select(
        LoginSelectors.selectCurityResponse,
    );

    yield call(
        handleCurityRequest,
        curityResponse.actions[0].model as LoginTypes.FormTemplateModel,
        action.payload,
    );

    yield call(handleCurityFlow);
}

/**
 * Auth flow
 * The idea is to use the action type provided to decide what
 * step to do next within a 'authentication-step' flow
 * @param {Object} action - Contains the type needed to call the correct authenticator option `CurityInitUNPW | InitBankID | InitFrejaID`
 */
export function* handleAuthFlow(action: LoginActions.CurityLoginAction): SagaIterator {
    const curityResponse = yield select(LoginSelectors.selectCurityResponse);
    const curityState: LoginTypes.CurityState = yield select(
        LoginSelectors.getCurityState,
    );

    const {actions, metadata, messages} = curityResponse;

    // Check if is reset password
    if (actions?.[0].kind === LoginTypes.CurityState.PASSWORD_RESET) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.PASSWORD_RESET));
    }

    // this viewName is only returned in FINNISH flow
    if (metadata?.viewName === "authentication-action/atg-set-password/index") {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.SET_PASSWORD));
    }

    if (curityResponse.actions?.[0].kind === "user-register") {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.ACTIVATE_USER));
        return;
    }

    if (
        curityState === "password-reset" &&
        curityResponse.actions?.[0].model.href.includes("/authn/authenticate/sms")
    ) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.SMS_VERIFICATION));
    }

    // User aborts freja or bankid auth on device
    if (
        metadata?.viewName === "authenticator/freja-eid/authenticate/error" ||
        messages?.[0].text === "Åtgärden avbruten."
    ) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.CANCELED));
        return;
    }

    if (action) {
        const authenticator: LoginTypes.SelectorAction = yield select(
            LoginSelectors.selectInitMethod(action.type as LoginTypes.InitMethods),
        );
        const payload = action;
        yield call(
            handleCurityRequest,
            authenticator.model as LoginTypes.FormTemplateModel,
        );
        yield call(handleAuthMethods, payload);
    }
}

/**
 * Handle authentication methods
 * Receive authentication method action, check each method has being used and
 * call `handleCurityFlow` to check the next step.
 *
 * Note: QR code methods pass directly to the handleCurityFlow only AtgCaptcha and EID_STATES will be handle inside this function
 * @param {Object} action - `LoginActions.CurityLoginAction`
 */
export function* handleAuthMethods(action: LoginActions.CurityLoginAction): SagaIterator {
    const curityState: LoginTypes.CurityState = yield select(
        LoginSelectors.getCurityState,
    );
    const authenticatorModel = yield select(LoginSelectors.selectAuthenticatorModel);
    const EID_STATES = [
        LoginTypes.CurityState.FREJAEID_OTHER_DEVICE,
        LoginTypes.CurityState.FREJAEID_SAME_DEVICE,
        LoginTypes.CurityState.AUTH_STARTER,
        LoginTypes.CurityState.BANKID_OTHER_DEVICE,
        LoginTypes.CurityState.BANKID_SAME_DEVICE,
    ];

    yield call(handleUpdateAuthMethod, action);

    if (EID_STATES.includes(curityState)) {
        yield call(
            handleEIDMethods,
            authenticatorModel,
            action as EIDActions.InitBankID | EIDActions.InitFrejaID,
        );
    }

    yield call(handleCurityFlow);
}

/**
 * Handle EID methods
 * Call curity api EID methods with personal number or bankId on same device(eg. desktop).
 * @param {Object} authenticatorModel - ` LoginTypes.FormTemplateModel`
 * @param {Object} action - `LoginActions.InitFrejaID | LoginActions.InitBankID`
 */
export function* handleEIDMethods(
    authenticatorModel: LoginTypes.FormTemplateModel,
    action: EIDActions.InitBankID | EIDActions.InitFrejaID,
) {
    if (action.type !== LoginTypes.ActionTypes.INIT_BANK_ID && action.payload) {
        yield call(handleCurityRequest, authenticatorModel, action.payload);
    } else {
        yield call(handleCurityRequest, authenticatorModel);
    }
}

/**
 * Handle update auth method
 * Update the authMethod and authContext if needed.
 * @param {Object} action - `LoginActions.CurityLoginAction`
 */
export function* handleUpdateAuthMethod(
    action?: LoginActions.CurityLoginAction,
): SagaIterator {
    if (!action?.type) return;

    const authenticatorType = yield select(
        LoginSelectors.selectAuthenticatorType(action.type),
    );

    if (authenticatorType) {
        yield put(LoginActions.setAuthContext(authenticatorType));
        yield call(setTempAuthMethod, action, authenticatorType);
    }
}

/**
 * Redirect flow
 * uses link to get the redirect response
 * check if need to open bankid app on same device, set the redirectResponse
 * in curityResponse state and go to `handleCurityFlow`.
 * @param {Object} link - `CurityTypes.RedirectionStep`
 */
export function* handleRedirectionFlow(link: LoginTypes.RedirectionStep): SagaIterator {
    const error = yield select(LoginSelectors.selectError);
    if (error) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.UNEXPECTED_ERROR));
        return false;
    }
    const {model} = link.actions[0];
    yield call(handleCurityRequest, model);

    return yield call(handleCurityFlow);
}

export function* loadAuthenticatorApp(response: LoginTypes.PollingStep): SagaIterator {
    const isWebViewEnv = LoginDevices.isWebView();
    const curityState = yield select(LoginSelectors.getCurityState);

    // if the user is in a webview, we should not redirect automatically, the user needs to click the link to open the external app
    if (isWebViewEnv) {
        return;
    }

    if (curityState === LoginTypes.CurityState.FREJAEID_SAME_DEVICE) {
        window.location.replace(response.links?.[0].href as string);
    }

    if (curityState === LoginTypes.CurityState.BANKID_SAME_DEVICE) {
        const model = response.actions[1].model as LoginTypes.ClientOperationModel;
        const href = model.arguments?.href as string;

        window.location.replace(href);
    }
    // Give the user 5 seconds to allow opening the app before we start polling
    // to avoid failed requests if the user leaves the browser in the middle of the fetch requests(polling).
    yield delay(5000);
}

/**
 * Get the visibility state
 * Check if the document visibility state is hidden or visible during polling process.
 *  @returns {boolean} - true if the document visibility state is visible
 * @returns {boolean} - false if the document visibility state is hidden
 * @returns {boolean} - true as default to keep polling process running
 */
function isDocumentVisible() {
    if (root.document.visibilityState === "hidden") {
        return false;
    }
    if (root.document.visibilityState === "visible") {
        return true;
    }
    return true;
}

/**
 * Polling step
 * Fetching interval 3 seconds.
 * If the intention is to open authenticator app on the same device it will first try to open it
 * Triggered as a race effect form `curitySaga` watcher.
 * @see {curitySaga}
 * @param {Object} link - `CurityTypes.PollingStep`
 */
export function* handlePolling(link: LoginTypes.PollingStep) {
    yield call(loadAuthenticatorApp, link);
    while (true) {
        try {
            if (isDocumentVisible()) {
                yield call(
                    handleCurityRequest,
                    link.actions[0].model as LoginTypes.FormTemplateModel,
                );

                const pollResponse: LoginTypes.PollingStep = yield select(
                    LoginSelectors.selectCurityResponse,
                );
                if (
                    pollResponse.properties.status === "failed" ||
                    pollResponse.properties.status === "done"
                ) {
                    yield call(handleDoneOrFailedPolling, pollResponse);
                }
            }
            yield delay(3000);
        } catch (error: unknown) {
            yield put(EIDActions.stopPolling());
            yield call(handleError, error);
            yield call(logLoginSagaError, "Error during polling step", error);
        }
        // Prevent test from infinite loop
        if (process.env.NODE_ENV === "test") break;
    }
}

/**
 * Done or failed polling
 * Handle done or failed polling and stop the polling process.
 * @param {Object} pollResponse - `LoginTypes.PollingStep`
 */
export function* handleDoneOrFailedPolling(pollResponse: LoginTypes.PollingStep) {
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING));
    yield call(
        handleCurityRequest,
        pollResponse.actions[0].model as LoginTypes.FormTemplateModel,
    );

    const doneOrFailedPollResponse: LoginTypes.PollingStep = yield select(
        LoginSelectors.selectCurityResponse,
    );

    if (pollResponse.properties.status === "failed") {
        if (
            doneOrFailedPollResponse.status >= 400 &&
            doneOrFailedPollResponse.actions[0].title
        ) {
            yield put(
                LoginActions.setCurityError({
                    messages: [
                        {
                            text: doneOrFailedPollResponse.actions[0].title,
                            classList: ["error"],
                        },
                    ],
                }),
            );
        }
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.ERROR_TIMED_OUT));
    }
    yield call(handleCurityFlow);
    yield put(EIDActions.stopPolling());
}

/**
 * Handle registration flow and check if should show
 * the register contact info dialog.
 * @param {Object} link - `LoginTypes.RegistrationStep`
 * @property {Array} link.actions - an array of `ClientOperationAction | FormAction | SelectorAction`
 */
export function* handleRegistrationFlow(link: LoginTypes.RegistrationStep) {
    if (link.actions && link.actions[0].kind === "user-register") {
        yield put(
            LoginActions.setCurityState(LoginTypes.CurityState.REGISTER_CONTACT_INFO),
        );
        yield put(LoginActions.setAuthMode(LoginTypes.AuthMode.Register));
    }
}

/**
 * This function will run recursively unless any of the
 * conditions and checks are met. The **link** argument will be saved in
 * Redux at the end of each time this function runs.
 * @param {Object} action - `LoginActions.CurityAction`
 */
export function* handleCurityFlow(action?: LoginActions.CurityAction): SagaIterator {
    const link = yield select(LoginSelectors.selectCurityResponse);
    switch (link?.type) {
        case LoginTypes.StepType.AuthenticationStep:
            yield call(handleAuthFlow, action as LoginActions.CurityLoginAction);
            break;
        case LoginTypes.StepType.RedirectionStep:
            yield call(handleRedirectionFlow, link);
            break;
        case LoginTypes.StepType.PollingStep:
            yield put(EIDActions.startPolling(link));
            return;
        case LoginTypes.StepType.RegistrationStep:
            yield call(handleRegistrationFlow, link);
            return;
        case LoginTypes.StepType.OAuthAuthorizationResponse:
            yield call(authorizeLogin, link.links?.[0]);
            return;
        case LoginTypes.StepType.ErrorAuthResponseStep:
            yield call(handleErrorAuthResponse, link);
            return;
        case LoginTypes.StepType.IncorrectCredentialsStep:
        case LoginTypes.StepType.AuthFailedStep:
        case LoginTypes.StepType.UnexpectedStep:
        case LoginTypes.StepType.TooManyAttemptsStep:
        case LoginTypes.StepType.GenericUserErrorStep:
        case LoginTypes.StepType.InvalidInputStep:
            yield put(LoginActions.setCurityError(link as LoginTypes.CurityError));
            // make a call and handle errors in saga
            break;
        default:
            break;
    }
}

/**
 * Handle SMS verification.
 * @param {Object} action - `VerifySms` action
 * @property {string} action.type - `curity/VERIFY_SMS`
 * @property {Object} action.payload - `CurityTypes.OTP`
 */
export function* verifySms(action: LoginActions.VerifySms): SagaIterator {
    const {actions} = yield select(LoginSelectors.selectCurityResponse);
    yield call(handleCurityRequest, actions[0].model, action.payload);

    const error = yield select(LoginSelectors.selectError);

    if (error) return;

    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING));

    const verifySmsResponse = yield select(LoginSelectors.selectCurityResponse);

    if (
        verifySmsResponse.type !== LoginTypes.StepType.IncorrectCredentialsStep &&
        verifySmsResponse.type !== LoginTypes.StepType.UnexpectedStep
    ) {
        yield call(handleCurityFlow);
    }
}

export function* generateNewSmsCode() {
    const {links} = yield select(LoginSelectors.selectCurityResponse);

    yield call(handleCurityRequest, links[0]);
    yield call(handleCurityFlow);
}

/**
 * Handle curity state to be sms verification
 * checking if the curity response model contains the SMS verification href.
 */
export function* handleSmsVerificationState(): SagaIterator {
    const curityResponse = yield select(LoginSelectors.selectCurityResponse);

    if (curityResponse.actions?.[0].model.href.includes("/authn/authenticate/sms")) {
        yield put(LoginActions.setCurityState(LoginTypes.CurityState.SMS_VERIFICATION));
    }
}

/**
 * Will use the restart links provided in the stored response to let the user continue the flow.
 * The link will call a new endpoint => set the response to the store => and run the `handleCurityFlow`
 * again but also store which auth context the user came from, so the user can restart their
 * auth in the same method as before.
 */
export function* restartFlow() {
    const {links}: LoginTypes.AuthenticationStep = yield select(
        LoginSelectors.selectCurityResponse,
    );

    const templateModel: LoginTypes.FormTemplateModel = {
        href: links?.[0].href as string,
        method: "GET",
    };

    yield call(handleCurityRequest, templateModel);

    const authContext: LoginTypes.CurityState = yield select(
        LoginSelectors.selectAuthContext,
    );

    yield put(LoginActions.setCurityState(authContext));
    yield call(handleCurityFlow);
}

/**
 * Stop polling process and call the action in latest response based on the payload provided.
 * @property {string} payload - This payload can be any of the curityState types.
 *
 * Note: Most likely only freja or bankid type will be used since there is no other polling going on in other states.
 */
export function* cancelPoll({payload}: EIDActions.CancelPoll) {
    yield put(EIDActions.stopPolling());

    const {actions}: LoginTypes.AuthenticationStep = yield select(
        LoginSelectors.selectCurityResponse,
    );
    const authContext: LoginTypes.AuthenticatorTypes = yield select(
        LoginSelectors.selectAuthContext,
    );

    if (authContext === "bankid") {
        yield call(handleCurityRequest, actions[2].model as LoginTypes.FormTemplateModel);
    }

    if (authContext === "freja-eid") {
        yield call(handleCurityRequest, actions[1].model as LoginTypes.FormTemplateModel);
    }

    yield put(LoginActions.setCurityState(payload));
    yield call(handleCurityFlow);
}

/**
 * For users who needs to activate their account before allowed to login.
 * @param action Type and payload with form data such as email and mobilenumber
 */
export function* handleActivateUser(action: LoginActions.ActivateUser): SagaIterator {
    const curityResponse: LoginTypes.AuthenticationStep = yield select(
        LoginSelectors.selectCurityResponse,
    );
    const model = curityResponse.actions[0].model as LoginTypes.FormTemplateModel;
    yield call(handleCurityRequest, model, action.payload);

    const error = yield select(LoginSelectors.selectError);
    if (error) return;

    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING));

    const activateUserResponse: LoginTypes.CurityRootModel = yield select(
        LoginSelectors.selectCurityResponse,
    );

    if (activateUserResponse.type === LoginTypes.StepType.RedirectionStep) {
        yield call(handleCurityFlow);
    }
}

/**
 * Handle logout request
 */
export function* logout(): SagaIterator {
    try {
        yield call(loginApiLogout);
    } catch (error: unknown) {
        yield call(logLoginSagaError, "Logout error", error);
    }
}

/**
 * Handle reset password from recovery flow.
 * @param {Object} action - CurityResetPassword action
 * @property {string} action.type - `curity/RESET_PASSWORD`
 * @property {Object} action.payload - `CurityTypes.ResetPassword`
 */
export function* handleResetPassword(
    action: LoginActions.CurityResetPassword,
): SagaIterator {
    const {actions} = yield select(LoginSelectors.selectCurityResponse);

    yield call(handleCurityRequest, actions[0].model, action.payload);
    return yield call(handleCurityFlow);
}

/**
 * Set a password after successfully Legal ID flow (Finland).
 * @param {Object} action - CurityResetPassword action
 * @property {string} action.type - `curity/SET_PASSWORD`
 * @property {Object} action.payload - `CurityTypes.SetPassword`
 */
export function* handleSetPassword(action: LoginActions.CuritySetPassword): SagaIterator {
    const {actions} = yield select(LoginSelectors.selectCurityResponse);

    yield call(handleCurityRequest, actions[0].model, action.payload);
    return yield call(handleCurityFlow);
}

/**
 * Handle ** MEMBER ** login or register flow action.
 * After everything related to member be removed from the project
 * the handleMemberLoginOrRegisterFlow can be removed.
 * @param {Object} action - start/register login flow action
 * @property {string} action.type - `START_LOGIN_FLOW` or `START_REGISTER_FLOW`
 * @property {Object} action.payload - `MemberFlowOptions` or an empty object
 */
export function* handleMemberLoginOrRegisterFlow(
    action: MemberActions.StartLoginFlowAction | MemberActions.StartRegisterFlowAction,
): Generator<CallEffect | PutEffect, void> {
    yield put(LoginActions.setCurityState(LoginTypes.CurityState.PENDING)); // show spinner immediately in case the user has a bad connection
    yield call(handleSSO);

    if (action.type === MemberActions.START_REGISTER_FLOW) {
        yield put(LoginActions.setAuthMode(LoginTypes.AuthMode.Register));
    } else {
        yield put(LoginActions.setAuthMode(LoginTypes.AuthMode.Login));
    }
    yield put(LoginActions.getCurityAuthenticators());
}

/**
 * Handle SSO will check for a valid access token and use the id token to authorize a user
 * once the LOGIN_FINISHED action is dispatched.
 * An example of this is when a user is moving between tillsammans and atg.se
 *
 * Note: This is a temporary solution to manage the SSO until tillsammans start using the new curity flow.
 */
export function* handleSSO(): SagaIterator {
    try {
        const res = yield call(checkSSO);

        if (
            res.type === LoginTypes.StepType.OAuthAuthorizationResponse &&
            res.properties
        ) {
            const {access_token: accessToken, id_token: idToken} = res.properties;
            yield put(
                AccessTokenActions.authenticationSuccess(
                    accessToken,
                    JSON.parse(idToken),
                ),
            );

            const action = yield take([LOGIN_FINISHED]);
            if (action?.type === LOGIN_FINISHED) {
                yield put(
                    UserGamblingSummaryActions.fetchLatestSuccessfulLoginTimeAction(),
                );
                yield put(UserGamblingSummaryActions.fetchGamblingResultAction());

                if (action.payload.loginData.status === "OK") {
                    yield put(MemberActions.memberRegisterFinished());
                }
            }
        }
    } catch (error: unknown) {
        yield call(logLoginSagaError, "SSO request error", error);
    }
}
/**
 * CheckSSO request to init-haapi endpoint to get the id_token and access token.
 *
 * Note: This is a temporary solution to manage the SSO until tillsammans start using the new curity flow.
 */
async function checkSSO() {
    const url = new URL(`${root.clientConfig.curity.tokenHandlerURI}/init-haapi`);

    const res = await fetch(url, {
        credentials: "include",
        method: "GET",
    })
        .then((response) => response.json())
        .catch((error) => {
            logLoginSagaError("CheckSSO /init-haapi error", error);
        });
    return res;
}

// Variable to track the timer task
let tokenMonitorTask: Task | null = null;

/**
 * The actual timer saga that waits for token expiration
 * This is designed to be completely non-blocking
 */
export function* tokenExpirationTimer(): SagaIterator {
    try {
        // Token lifetime is 10 minutes (600,000 ms)
        const TOKEN_LIFETIME = 10 * 60 * 1000;

        // Give a small buffer to ensure we trigger before actual expiration
        const timeToExpire = TOKEN_LIFETIME - 5000;

        // Wait until the token is about to expire
        yield delay(timeToExpire);

        yield call(handleExpiredThumbprint);
    } catch (error: unknown) {
        logLoginSagaError("Error in token expiration timer", error);
    } finally {
        // Clear the reference when done
        tokenMonitorTask = null;
    }
}

/**
 * Starts the token expiration timer
 * This is a separate saga that spawns the timer without blocking
 */
export function* startTokenExpirationMonitor(): SagaIterator {
    // Cancel any existing token monitor first
    if (tokenMonitorTask) {
        yield cancel(tokenMonitorTask);
        tokenMonitorTask = null;
    }

    // Spawn a new timer task that runs independently
    // Using spawn ensures it won't block and runs in the background
    tokenMonitorTask = yield spawn(tokenExpirationTimer);
}

/**
 * Stops the token expiration timer if it's running
 */
export function* stopTokenExpirationMonitor(): SagaIterator {
    if (tokenMonitorTask) {
        yield cancel(tokenMonitorTask);
        tokenMonitorTask = null;
    }
}

/**
 * The race effect will handle and execute the task that finishes first
 * the other tasked will automatically be cancelled.
 * A cancel effect can also be triggered bi dispatching the `stopPolling` action to the saga middleware
 */
export default function* curitySaga(): SagaIterator {
    yield takeLatest(
        LoginTypes.ActionTypes.START_TOKEN_MONITOR,
        startTokenExpirationMonitor,
    );
    yield takeLatest(
        LoginTypes.ActionTypes.STOP_TOKEN_MONITOR,
        stopTokenExpirationMonitor,
    );
    yield takeLatest(
        [MemberActions.START_LOGIN_FLOW, MemberActions.START_REGISTER_FLOW],
        handleMemberLoginOrRegisterFlow,
    );
    yield takeLatest(LoginTypes.ActionTypes.GET_CURITY_AUTHENTICATORS, initSaga);
    yield takeLatest(LoginTypes.ActionTypes.SELECT_AUTH_METHOD, selectAuthMethod);
    yield takeLatest(LoginTypes.ActionTypes.VERIFY_SMS, verifySms);
    yield takeLatest(LoginTypes.ActionTypes.GENERATE_NEW_SMS_CODE, generateNewSmsCode);
    yield takeLatest(LoginTypes.ActionTypes.RESTART_FLOW, restartFlow);
    yield takeLatest(LOGOUT_USER, logout);
    yield takeLatest(LoginTypes.ActionTypes.REGISTER_USER_CONTACT_INFO, registerUser);
    yield takeLatest(LoginTypes.ActionTypes.RESET_PASSWORD, handleResetPassword);
    yield takeLatest(LoginTypes.ActionTypes.SET_PASSWORD, handleSetPassword);
    yield takeLatest(LoginTypes.ActionTypes.ACTIVATE_USER, handleActivateUser);
    yield takeLatest(
        LoginTypes.ActionTypes.CURITY_SUBMIT_UNPW,
        submitUsernameAndPassword,
    );
    yield takeLatest(
        LoginTypes.ActionTypes.CURITY_SUBMIT_FINNISH_LEGAL_ID,
        submitLegalID,
    );
    yield takeLatest(
        [
            LoginTypes.ActionTypes.CURITY_INIT_UNPW,
            LoginTypes.ActionTypes.INIT_BANK_ID_QR,
            LoginTypes.ActionTypes.INIT_BANK_ID,
            LoginTypes.ActionTypes.INIT_FREJA_ID,
            LoginTypes.ActionTypes.INIT_FREJA_ID_QR,
            LoginTypes.ActionTypes.CURITY_INIT_FINNISH_MOCK,
            LoginTypes.ActionTypes.CURITY_INIT_FINNISH_UNPW,
        ],
        handleCurityFlow,
    );
    yield takeLeading(LoginTypes.ActionTypes.CANCEL_POLL, cancelPoll);
    yield takeLatest(LoginTypes.ActionTypes.LOGIN_ERROR, handleErrorAfterCurityFlow);
    yield takeLatest([MemberActions.CANCELLED_LOGIN_FLOW], resetStates);
    while (true) {
        const action: EIDActions.StartPolling = yield take(
            LoginTypes.ActionTypes.START_POLLING,
        );
        yield race([
            call(handlePolling, action.payload),
            take(LoginTypes.ActionTypes.STOP_POLLING),
        ]);
    }
}
